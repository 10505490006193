import { useToggle } from 'hooks'
import { useLayoutEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { IFloatingMenu } from './props'
import './styles.scss'

const FloatingMenu = ({
  pathCurrent,
  path,
}: IFloatingMenu) => {
  const refContent = useRef<HTMLDivElement>(null)
  const refBody = useRef<HTMLDivElement>(null)
  const [open, toggleOpen] = useToggle(false)
  
  const isPathCurrent = () => {
    if(pathCurrent === path.path) return 'active'
  }

  const isSubPathCurrent = (path: string) => {
    if(pathCurrent === path) return 'active'
  }

  const renderList = () => {
    const rows = []
    const columnsPerRow = 8
    const data = [...path.list]

    for(let i = 0; i < path.list?.length; i += columnsPerRow) {
      const rowItems = data.slice(i, i + columnsPerRow)

      const row = (
        <div className="grid-row" key={`row-${i}`}>
          {rowItems.map((item, index) => (
            <div className={`item ${isSubPathCurrent(item.path)}`} key={index}>
              <Link to={item.path}>
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      )

      rows.push(row)
    }

    return <>{rows}</>
  }

  useLayoutEffect(() => {
    refContent.current?.addEventListener('mouseover', toggleOpen)
    refContent.current?.addEventListener('mouseout', toggleOpen)

    refBody.current?.style.setProperty('top', `${refContent.current?.offsetHeight}px`)
  
    return () => {
      refContent.current?.removeEventListener('mouseover', toggleOpen)
      refContent.current?.removeEventListener('mouseout', toggleOpen)
    }
  }, [open, path])

  return (
    <div
      className={`floating-menu ${isPathCurrent()}`}
      ref={refContent}
    >
      <Link className='link' to={path.path}>
        {path.title}
      </Link>

      {(open && path.list?.length > 0) && (
        <div className='body' ref={refBody}>
          {renderList()}
        </div>
      )}
    </div>
  )
}

export default FloatingMenu