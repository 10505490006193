import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import links from 'constants/links'
import { Link } from 'react-router-dom'
import API from 'tools/api'
import { IGetInformation } from './props'

export const getInformation = async({
  information,
  setLoading,
  setItem,
  navigate,
}: IGetInformation) => {
  try{
    setLoading(true)

    if(!information) throw new Error('Not found')

    const response =  await API.request({
      method: 'GET',
      path: endpoints.pathInformation.replace(':information', information),
    })

    if(!response.data) throw new Error(response.error)

    setItem(response.data)
  }catch(error: any){
    console.error(error)

    if(error.message === 'Not found'){
      return navigate(links.notFound)
    }

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later',
    })
  }finally{
    setLoading(false)
  }
}

export const renderElement = (props: any) => {
  switch (props.element.type) {
    case 'indent':
      return <div {...props.attributes} style={{ marginLeft: '1rem' }}>{props.children}</div>
    case 'h1':
      return <h1 {...props.attributes}>{props.children}</h1>
    case 'h2':
      return <h2 {...props.attributes}>{props.children}</h2>
    case 'h3':
      return <h3 {...props.attributes}>{props.children}</h3>
    case 'h4':
      return <h4 {...props.attributes}>{props.children}</h4>
    case 'h5':
      return <h5 {...props.attributes}>{props.children}</h5>
    case 'h6':
      return <h6 {...props.attributes}>{props.children}</h6>
    case 'link':
      if(
        props.element.linkType === 'internal' ||
        !props.element.url.includes('http')
      ){
        return (
          <Link
            to={props.element?.doc?.value?.path || props.element.url}
            target={props.element.newTab ? '_blank' : '_self'}
            rel='noreferrer'
          >
            {props.children}
          </Link>
        )
      }else{
        return (
          <a
            href={props.element.url}
            target={props.element.newTab ? '_blank' : '_self'}
            rel='noreferrer'
          >
            {props.children}
          </a>
        )
      }
    case 'ul':
      return <ul {...props.attributes}>{props.children}</ul>
    case 'ol':
      return <ol {...props.attributes}>{props.children}</ol>
    case 'li':
      return <li {...props.attributes}>{props.children}</li>
    default:
      return <p {...props.attributes}>{props.children}</p>
  }
}

export const renderLeaf = (props: any) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? 'bold' : 'normal',
        fontStyle: props.leaf.italic ? 'italic' : 'normal',
        textDecoration: props.leaf.underline ? 'underline' : 'none',
        textDecorationLine: props.leaf.strikethrough ? 'line-through' : 'none',
      }}
    >
      {props.children}
    </span>
  )
}