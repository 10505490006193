import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import links from 'constants/links'
import API from 'tools/api'
import { IGetCategory, IGetSubcategories } from './props'

export const getCategory = async({
  category,
  setLoading,
  setItem,
  navigate,
}: IGetCategory) => {
  try{
    setLoading(true)

    if(!category) throw new Error('Not found')

    const response =  await API.request({
      method: 'GET',
      path: endpoints.pathCategory.replace(':category', category),
    })

    if(!response.data) throw new Error(response.error)

    setItem(response.data)
  }catch(error: any){
    console.error(error)

    if(error.message === 'Not found'){
      return navigate(links.notFound)
    }

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later',
    })
  }finally{
    setLoading(false)
  }
}

export const getSubcategories = async({
  setLoading,
  category,
  setSubItem,
  navigate,
  page,
}: IGetSubcategories) => {
  try{
    setLoading(true)

    if(!category) throw new Error('Not found')

    const currentPage = `${page || 1}`

    const response =  await API.request({
      method: 'GET',
      path: endpoints.pathSubcategories.replace(':category', category).replace(':page', currentPage),
    })

    if(!response.data) throw new Error(response.error)

    setSubItem(response.data)
  }catch(error: any){
    console.error(error)

    if(error.message === 'Not found'){
      return navigate(links.notFound)
    }

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later',
    })
  }finally{
    setLoading(false)
  }
}