import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import API from 'tools/api'
import { IGetHome } from './props'

export const getHome = async({
  setItems,
  setLoading,
}: IGetHome) => {
  try{
    setLoading(true)

    const response =  await API.request({
      method: 'GET',
      path: endpoints.pathCategory.replace(':category', ''),
    })

    if(!response.data) throw new Error()

    setItems(response.data)
  }catch(error){
    console.error(error)

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later',
    })
  }finally{
    setLoading(false)
  }
}