import { Link } from 'react-router-dom'
import { IListPath } from './props'
import './styles.scss'

const ListPath = ({
  item
}: IListPath) => {

  return (
    <div className='list-path'>
      <Link className='title' to={item.path}>{item.title}</Link>
      {
        item.list?.map((i, index) => (
          <Link key={index} to={i.path}>{i.title}</Link>
        ))
      }
    </div>
  )
}

export default ListPath