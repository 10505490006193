import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import API from './api'

export const validateSizeScreen = (screen: { width: number }, size: number) => {
  return screen.width <= size
}

export const getPaths = async(setPaths: Function, setLinksFooter: Function) => {
  try{
    const response =  await API.request({
      method: 'GET',
      path: endpoints.path,
    })

    if(!response.data) throw new Error()

    setPaths(response.data.paths)
    setLinksFooter(response.data.linksFooter)
  }catch(error){
    console.error(error)
  
    toast({
      type: 'error',
      message: 'Something went wrong, please try again later'
    })
  }
}