import { IButton } from './props'
import './styles.scss'

const Button = ({
  text,
  onClick,
  color,
  backgroundColor,
  size,
  type,
  loading,
  className,
}: IButton) => {
  return (
    <button
      type={type}
      className={`
        button
        fw-${size === 'lg' ? '700' : '500'}
        br-${size === 'lg' ? '16' : '10'}
        fs-${size === 'lg' ? '5' : '4'}
        px-16
        py-${size === 'lg' ? '4' : '3'}
        color-${color || 'white'}
        bg-${backgroundColor || 'orange'}
        ${className || ''}
      `}
      onClick={loading ? undefined : onClick}
      disabled={loading}
    >
      {loading ? 'Loading...' : text}
    </button>
  )
}

Button.defaultProps = {
  text: 'button',
}

export default Button