import { createElement } from 'react'
import ReactDOM from 'react-dom/client'
import ToastContainer from './childrens/toastContainer'
import { IToast } from './props'
import './styles.scss'

let ctToastCount = 0
let root: ReactDOM.Root | null = null

const toast = (options: IToast) => {
	if (!root) {
		const rootContainer = document.getElementById('ct-container') || document.createElement('div')
    rootContainer.id = 'ct-container'
    document.getElementById('root')
			? document.getElementById('root')?.appendChild(rootContainer)
			: document.body.appendChild(rootContainer)

    root = ReactDOM.createRoot(rootContainer)
	}

	ctToastCount++

	const toast = {
		id: ctToastCount,
		message: options.message,
		type: options?.type || 'info',
		position: options?.position || 'top-right',
		hideAfter: options?.hideAfter || 3,
		heading: options?.heading || '',
	}

	const app = createElement(ToastContainer, { toast })
  root.render(app)
}


export default toast