import { Footer, Header, InfoCard, PointCard } from 'components'
import { useGlobal } from 'context/global'
import { useMediaQuery } from 'hooks'
import { ISubCategory } from 'interfaces/subcategory.interface'
import { useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSubCategory } from './services'

const Subcategory = () => {
  const { category, subcategory } = useParams()
  const navigate = useNavigate()
  const { setLoading } = useGlobal()
  const isMobileScreen = useMediaQuery(768)

  const [item, setItem] = useState<ISubCategory>()

  useLayoutEffect(() => {
    if(!subcategory) return
    window.scrollTo(0, 0)

    document.title =  item?.title || 'SubCategory'
  }, [window.location.pathname, item?.title])

  useLayoutEffect(() => {
    getSubCategory({
      category,
      subcategory,
      setLoading,
      setItem,
      navigate,
    })
  }, [window.location.pathname])

  return (
    <>
      <Header/>

      <InfoCard
        title={item?.title}
        titleTextTransform='capitalize'
        description={
          isMobileScreen
            ? item?.descriptionMobile || item?.description || ''
            : item?.description || ''
        }
        image={item?.image?.url || ''}
        imageInMobile={false}
        link={item?.buttonLink}
        linkText={item?.buttonText}
        backgroundImage={isMobileScreen ? item?.image?.url : ''}
        backgroundColor={item?.backgroundColor}
        className={isMobileScreen ? 'pt-12' : ''}
        titleColor='white'
        textColor='white'
      />

      <InfoCard
        title={item?.secondaryTitle}
        titleTextTransform='uppercase'
        description={item?.secondaryDescription || ''}
        image={item?.secondaryImage?.url || ''}
        backgroundColor='lightGray'
      />

      <PointCard
        title={item?.tertiaryTitle || ''}
        image={item?.tertiaryImage?.url|| ''}
        link={item?.tertiaryButtonLink || ''}
        linkText={item?.tertiaryButtonText}
        points={item?.points?.map((point: { point: string }) => point.point) || []}
      />

      <Footer/>
    </>
  )
}

export default Subcategory