import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import { IInfoCard } from './props'
import './styles.scss'

const InfoCard = ({
  title,
  titleTextTransform,
  titleFontWeight,
  description,
  image,
  imageInMobile,
  link,
  linkText,
  titleColor,
  mobileTextAlign,
  backgroundImage,
  backgroundColor,
  textColor,
  buttonTextColor,
  buttonColor,
  className,
}: IInfoCard) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if(!link) return
    if(link.includes('http')) return window.open(link, '_blank')
    else return navigate(link)
  }
  return (
    <div
      className={`info-card bg-${backgroundColor} ${className}`}
      style={{
        ...(backgroundImage && {
          background: `
            linear-gradient(0deg, #1F2A5F 0%, rgba(31, 42, 95, 0.39) 34.89%, rgba(31, 42, 95, 0) 84.95%),
            linear-gradient(0deg, #171848 0%, rgba(23, 24, 72, 0.38) 15.56%, rgba(23, 24, 72, 0.13) 69.47%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            url('${backgroundImage}')
            center center / cover no-repeat
          `,
        }),
      }}
    >
      <div className='container'>
        <div className={`left ta-mobile-${mobileTextAlign}`}>
          <h2 className={`fw-${titleFontWeight} color-${titleColor} tt-${titleTextTransform}`}>
            {title}
          </h2>
          <p className={`color-${textColor}`}>
            {description}
          </p>
          {linkText &&
            <Button
              text={linkText}
              size='md'
              onClick={handleClick}
              backgroundColor={buttonColor}
              color={buttonTextColor}
            />
          }
        </div>

        <div className={`right ${!imageInMobile && 'only-desktop'}`}>
          <img src={image} alt={title} />
        </div>
      </div>
    </div>
  )
}

InfoCard.defaultProps = {
  titleFontWeight: '500',
  mobileTextAlign: 'left',
  imageInMobile: true,
  backgroundColor: 'white',
  titleColor: 'black',
  textColor: 'black',
  buttonTextColor: 'white',
  buttonColor: 'orange',
  className: '',
}

export default InfoCard