import Icon from 'components/icon'
import { useMediaQuery, useOnClickOutside } from 'hooks'
import { useLayoutEffect, useRef, useState } from 'react'
import { IInputSearch } from './props'
import './styles.scss'

const InputSearch = ({
  placeholder,
  icon,
  listIcon,
  value,
  onChange,
  className,
  renderBody,
}: IInputSearch) => {
  const refContent = useRef<HTMLInputElement>(null)
  const isMobile = useMediaQuery(768)
  const refBody = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  useOnClickOutside(refContent, () => isMobile && setOpen(false))

  useLayoutEffect(() => {
    const top = refContent.current?.offsetHeight || 0
    refBody.current?.style.setProperty('top', `${top - 1}px`)
  }, [value, open])

  return (
    <div
      className={`input_search-container ${className || ''} ${(open && value) ? 'br-top' : 'br-all'}`}
      ref={refContent}
      onMouseOver={!isMobile ? () => setOpen(true) : () => {}}
      onMouseOut={!isMobile ? () => setOpen(false) : () => {}}
    >
      {icon && (
        <Icon
          name={icon}
          list={listIcon}
          width='20'
          height='20'
        />
      )}

      <input
        className='input'
        placeholder={placeholder}
        value={value}
        onFocus={isMobile ? () => setOpen(true) : () => {}}
        onChange={(e) => onChange(e.target.value)}
      />

      <div className='close'>
        {value && (
          <Icon
            name='close'
            list={listIcon}
            className='c-pointer'
            width='14'
            height='14'
            fill='white'
            onClick={() => {
              onChange('')
              setOpen(false)
            }}
          />
        )}
      </div>

      {(open && value) && (
        <div
          ref={refBody}
          className='body'
        >
          {renderBody?.()}
        </div>
      )}
    </div>
  )
}

export default InputSearch