import { IInput } from './props'
import './styles.scss'

const Input = ({
  value,
  onChange,
  error,
  placeholder,
  label,
  type,
  className,
}: IInput) => {
  return (
    <div className={`input ${className || ''}`}>
      {label &&
        <label className={`label ${error ? 't-error' : ''}`}>
          {label}
        </label>
      }

      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className={error ? 't-error' : ''}
        onChange={(e) => onChange?.(e.target.value)}
      />

      {error &&
        <span className='error'>
          {error}
        </span>
      }
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
}

export default Input