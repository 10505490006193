import { Footer, Header, InfoCard, Loading, Pagination } from 'components'
import { useGlobal } from 'context/global'
import { ICategory, ISubCategories } from 'interfaces/category.interface'
import { useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getCategory, getSubcategories } from './services'

const Category = () => {
  const navigate = useNavigate()
  const { category } = useParams()
  const { setLoading } = useGlobal()

  const [loadingSubItem, setLoadingSubItem] = useState<boolean>(false)
  const [item, setItem] = useState<ICategory>()
  const [subItem, setSubItem] = useState<ISubCategories>({
    list: [],
    totalPages: 0,
    category,
    page: 1,
  })

  useLayoutEffect(() => {
    if(!category) return
    window.scrollTo(0, 0)

    document.title =  item?.title || 'Category'
  }, [window.location.pathname, item?.title])


  useLayoutEffect(() => {
    getCategory({
      category,
      setLoading,
      setItem,
      navigate,
    })

    setSubItem({
      list: [],
      totalPages: 0,
      category,
      page: 1,
    })

  }, [window.location.pathname])

  useLayoutEffect(() => {
    getSubcategories({
      setLoading: setLoadingSubItem,
      setSubItem,
      category,
      navigate,
      page: subItem.page,
    })
  }, [subItem.page, subItem?.category])

  return (
    <>
      <Header/>

      <InfoCard
        title={item?.title}
        titleTextTransform='capitalize'
        description={item?.description || ''}
        image={item?.image?.url || ''}
        imageInMobile={false}
        link={item?.buttonLink}
        linkText={item?.buttonText}
        backgroundColor={item?.backgroundColor}
        titleColor='white'
        textColor='white'
      />

      {
        loadingSubItem
        ? <div className='container mt-12'><Loading /></div>
        : 
        <>
          {subItem?.list?.map((item, index) => (
            <InfoCard
              key={index}
              title={item.title}
              description={item.description}
              image={item.image?.url}
              link={item.path}
              linkText='Learn More'
              textColor='lightBlack'
              backgroundColor={index % 2 === 0 ? 'white' : 'lightGray'}
              buttonTextColor='white'
              buttonColor={index % 2 === 0 ? 'orange' : 'blue'}
            />
          ))}
        </>
      }

      {subItem?.totalPages > 1 &&
        <Pagination
          currentPage={subItem.page}
          totalPages={subItem.totalPages}
          handlePagination={(page: number) => {
            window.scrollTo(0, 0)
            setSubItem({ ...subItem, page })
          }}
        />
      }
      <Footer/>
    </>
  )
}

export default Category