import { Button, Checkbox, Footer, Header, Icon, Input, Modal, Textarea } from 'components'
import colors from 'constants/colors'
import links from 'constants/links'
import { useToggle } from 'hooks'
import { IProductTour } from 'interfaces/tour.interface'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { rules } from './rules'
import { sendEmail } from './services'
import './styles.scss'

const Contact = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openFinish, toggleFinish] = useToggle(false)

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<IProductTour>({
    mode: 'onSubmit',
  })

  const onSubmit = async(data: IProductTour) => {
    await sendEmail({
      grecaptcha: window.grecaptcha,
      setLoading,
      data,
      toggleFinish,
    })
  }

  const onReset = () => {
    setValue('firstName', '')
    setValue('lastName', '')
    setValue('companyName', '')
    setValue('phone', '')
    setValue('companyWebsite', '')
    setValue('companySize', '')
    setValue('productToDiscuss', '')
    setValue('industry', '')
    setValue('message', '')
    setValue('accepted', false)
    toggleFinish()
  }

  const redirectHome = () => {
    navigate(links.home)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Product Tour'
  }, [])

  return (
    <>
      <Header/>

      <div className='product-tour'>
        <div className='background'/>

        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <h3>
            Unlock TIORY's Solutions: Discover More Today!
          </h3>

          <p>
            Take the first step towards revolutionizing your operations by discovering TIORY's comprehensive suite of products. Start now!
          </p>

          <div className='box'>
            <Controller
              name='firstName'
              control={control}
              rules={rules.firstName}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='First Name *'
                  placeholder=''
                  error={errors.firstName?.message}
                />
              )}
            />

            <Controller
              name='lastName'
              control={control}
              rules={rules.lastName}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='Last Name *'
                  placeholder=''
                  error={errors.lastName?.message}
                />
              )}
            />
          </div>

          <div className='box'>
            <Controller
              name='companyName'
              control={control}
              rules={rules.companyName}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  type='text'
                  onChange={onChange}
                  label='Company Name *'
                  placeholder=''
                  error={errors.companyName?.message}
                />
              )}
            />

            <Controller
              name='phone'
              control={control}
              rules={rules.phone}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='Phone number *'
                  placeholder=''
                  error={errors.phone?.message}
                />
              )}
            />
          </div>

          <div className="box">
            <Controller
              name='companyWebsite'
              control={control}
              rules={rules.companyWebsite}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='Company Website'
                  placeholder=''
                />
              )}
            />

            <Controller
              name='companySize'
              control={control}
              rules={rules.companySize}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='Company Size'
                  placeholder=''
                />
              )}
            />
          </div>

          <div className="box">
            <Controller
              name='productToDiscuss'
              control={control}
              rules={rules.productToDiscuss}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='Product to Discuss *'
                  placeholder=''
                  error={errors.productToDiscuss?.message}
                />
              )}
            />

            <Controller
              name='industry'
              control={control}
              rules={rules.industry}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  label='Industry *'
                  placeholder=''
                  error={errors.industry?.message}
                />
              )}
            />
          </div>
    
          <Controller
            name='message'
            control={control}
            rules={rules.message}
            render={({ field: { onChange, value } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                label='Your message'
                placeholder='Comments'
              />
            )}
          />

          <Controller
            name='accepted'
            control={control}
            rules={rules.accepted}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                value={value}
                onChange={onChange}
                id='accepted'
                text='I Accept'
                linkText='Terms & Conditions.'
                linkHref='/terms-and-conditions'
                error={errors.accepted?.message}
              />
            )}
          />

          <Button
            size='md'
            text='Submit'
            type='submit'
            loading={loading}
          />
        </form>

        <Modal
          open={openFinish}
          onClose={onReset}
          className='modal-finish'
        >
          <Icon
            name='circle-check'
            list='icons'
            fill={colors.green}
            width={'88'}
            height={'88'}
          />

          <h2>
            Request sent successfully!
          </h2>

          <p>
            Thank you! Our support team will contact you soon.
          </p>

          <Button
            text='Explore more'
            onClick={redirectHome}
            size='md'
          />
        </Modal>
      </div>

      <Footer />
    </>
  )
}

export default Contact