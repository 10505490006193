import logo from 'assets/images/logo.svg'
import { Accordion, Icon, InputSearch, Sidebar } from 'components'
import links from 'constants/links'
import { useGlobal } from 'context/global'
import { Link } from 'react-router-dom'
import { INavbarMobile } from './props'
import './styles.scss'

const NavbarMobile = ({
  toggleSidebar,
  openSidebar,
  loadingSearch,
  resultsSearch,
  returnMathes,
}: INavbarMobile) => {
  const { search, setSearch, paths } = useGlobal()
  
  return (
    <>
      <Sidebar
        classNameSidebar='navbar-mobile'
        classNameContent='content'
        open={openSidebar}
        onClose={toggleSidebar}
      >
        <div className='top'>
          <Icon
            name='menu'
            className='icon-menu'
            list='icons'
            onClick={toggleSidebar}
          />
          <Link
            to={links.home}
          >
            <img
              src={logo}
              alt='logo'
              className='logo'
            />
          </Link>
        </div>

        <div className='body'>
          <InputSearch
            placeholder='Search'
            icon='search'
            className='mx-4 w-auto mb-2'
            listIcon='icons'
            value={search}
            onChange={setSearch}
            renderBody={() => (
              <div className='body-search'>
                {loadingSearch && <span className='title'>Searching...</span>}
                {!loadingSearch && (
                  <>
                    {resultsSearch?.length === 0 && <span className='title'>No results</span>}
                    {resultsSearch?.length > 0 &&
                      <>
                        <span className='title'>Matches found</span>
                        {resultsSearch?.map((result, index: number) => (
                          <Link
                            key={index}
                            to={result.path}
                            className='result'
                          >
                            <span
                              className='subcategory'
                            >
                              {result.title} ({returnMathes(result, search)})
                            </span>
                          </Link>
                        )
                        )}
                      </>
                    }
                  </>
                )}
              </div>
            )}
          />
          {paths.map((item, index) => (
            <Accordion
              key={index}
              pathCurrent={window.location.pathname}
              path={item}
              openInitial={item.list?.some((i) => window.location.pathname === i.path)}
            />
          ))}
        </div>

      </Sidebar>
    </>
  )
}

export default NavbarMobile