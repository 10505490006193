import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import { IPointCard } from './props'
import './styles.scss'

const PointCard = ({
  title,
  image,
  link,
  linkText,
  points,
}: IPointCard) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if(!link) return
    if(link.includes('http')) return window.open(link, '_blank')
    else return navigate(link)
  }

  return (
    <div className='point-card bg-white'>
      <div className='container'>
        <div className='left'>
          {image && <img src={image} alt={title} />}
        </div>

        <div className='right'>
          <h2>
            {title}
          </h2>

          {points && (
            <ul>
              {points.map((point, index) => (
                <li key={index}>
                  {point}
                </li>
              ))}
            </ul>
          )}
  
          {linkText &&
            <Button
              text={linkText}
              size='md'
              onClick={handleClick}
              backgroundColor='orange'
              color='white'
            />
          }
        </div>
      </div>
    </div>
  )
}

export default PointCard