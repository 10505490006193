import { IPath } from 'interfaces/path.interface'
import React, { createContext, useContext, useState } from 'react'
import { IGlobalContext } from './props'

const GlobalContext = createContext<IGlobalContext | null>(null)

export const useGlobal = (): IGlobalContext => {
  const context = useContext(GlobalContext)
  if (!context) throw new Error('useGlobal must be used within a GlobalProvider')
  return context
}

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [linksFooter, setLinksFooter] = useState([])
  const [paths, setPaths] = useState<IPath[]>([
    {
      title: 'Products',
      path: '/categories/products',
      list: []
    },
    {
      title: 'Industries',
      path: '/categories/industries',
      list: []
    },
    {
      title: 'Services',
      path: '/categories/services',
      list: []
    },
    {
      title: 'Contact us',
      path: '/categories/contact',
      list: []
    }
  ])

  const values = {
    loading,
    setLoading,
    paths,
    setPaths,
    search,
    setSearch,
    linksFooter,
    setLinksFooter,
  }

  return (
    <GlobalContext.Provider value={values}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider