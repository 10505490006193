import { Icon } from 'components'
import { useLayoutEffect, useRef } from 'react'
import { IModal } from './props'
import './styles.scss'

const Modal = ({
  open,
  onClose,
  children,
  closeInClickOut,
  className,
}: IModal) => {
  const refContent = useRef<HTMLDivElement>(null)

  const onKeyDown = (e: KeyboardEvent) => {
    const isEscape = e.key === 'Escape'

    if(isEscape) onClose()
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(!closeInClickOut) return
  
    const isClickRight = e.button === 0
    const isOut = !(refContent?.current?.contains(e.target as Element))

    if(isClickRight && isOut) onClose()
  }

  useLayoutEffect(() => {
    if(open && closeInClickOut) {
      document.addEventListener('keydown', onKeyDown)
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.body.style.overflow = 'visible'
    }
  }, [open])

  if (!open) return null

  return (
    <div
      className='modal'
      onMouseDown={onMouseDown}
    >
      <div
        ref={refContent}
        className={
          `content ${className || ''}`
        }
      >
        <Icon 
          name='close'
          className='close'
          list='icons'
          onClick={onClose}
        />

        {children}
      </div>
    </div>
  )
}

Modal.defaultProps = {
  closeInClickOut: true,
}

export default Modal