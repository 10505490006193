import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import envs from 'constants/envs'
import API from 'tools/api'
import { ISendEmail } from './props'

export const sendEmail = async({
  grecaptcha,
  setLoading,
  data,
  toggleFinish,
}: ISendEmail) => {
  try{
    setLoading(true)

    const token = await grecaptcha.execute(envs.captchaKey, { action: 'captcha' })

    const response = await API.request({
      method: 'POST',
      path: endpoints.productTour,
      body: data,
      headers: {
        'g-recaptcha-response': token,
      }
    })

    if(response.status !== 200) throw new Error()
    toggleFinish()
  }catch(error){
    console.error(error)

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later',
    })
  }finally{
    setLoading(false)
  }
}