import Icon from 'components/icon'
import colors from 'constants/colors'
import { useToggle } from 'hooks'
import { Link } from 'react-router-dom'
import { IAccordion } from './props'
import './styles.scss'

const Accordion = ({
  openInitial,
  pathCurrent,
  path,
}: IAccordion) => {
  const [open, toggleOpen] = useToggle(openInitial)

  const isPathCurrent = () => {
    if(pathCurrent === path.path) return 'active'
  }

  const isSubPathCurrent = (path: string) => {
    if(pathCurrent === path) return 'active'
  }

  return (
    <div className='accordion'>
      <div className={`title ${isPathCurrent()}`}>
        <Link to={path.path}>
          {path.title}
        </Link>

        {(path.list?.length > 0) && (
          <Icon
            name='arrow'
            stroke={pathCurrent === path.path ? colors.white : colors.deepBlue}
            className={open ? 'rotate-180' : ''}
            list='icons'
            onClick={toggleOpen}
          />
        )}
      </div>

      {open && (
        <div className='body'>
          {path.list?.map((item, index) => (
            <div className={`item ${isSubPathCurrent(item.path)}`} key={index}>
              <Link to={item.path}>
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Accordion