import { ILoading } from './props'
import './styles.scss'

const Loading = ({
  complete
}: ILoading) => {
  return (
    <div className={`loading ${complete ? 'complete' : ''}`}>
      <div className='wrapper-spinner'>
        <span className="spinner" />
      </div>
    </div>
  )
}

Loading.defaultProps = {
  complete: false
}

export default Loading