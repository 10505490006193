import axios, { AxiosError, AxiosResponse } from 'axios'
import envs from 'constants/envs'

interface IFetch {
  path: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  body?: {}
  params?: {}
  headers?: {}
  hasToken?: boolean
}

class API {
  static request = async ({
    method = 'GET',
    hasToken = false,
    path,
    body,
    params,
    headers,
  }: IFetch) => {
    const response = await axios.create({
      baseURL: envs.api,
    })(
      {
        url: path,
        headers: {
          ...(hasToken && {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }),
          ...headers,
        },
        params,
        data: body,
        method,
      }
    )
    .then((response: AxiosResponse) => {
      return response?.data?.response || response
    })
    .catch((error: AxiosError) => {
      return error?.response?.data || error
    })

    return response
  }
}

export default API