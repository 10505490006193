import Icon from 'components/icon'
import { useEffect, useState } from 'react'
import { IPagination } from './props'
import './styles.scss'

type NumberOrString = number | string

const Pagination = ({
  currentPage,
  totalPages,
  handlePagination,
}: IPagination) => {
  const [pages, setPages] = useState<NumberOrString[]>([])

  useEffect(() => {
    if (totalPages <= 5) {
      let array = []
      for (let i = 1; i <= totalPages; i++) {
        array.push(i) 
      }
      return setPages(array)
    }

    if (currentPage <= 2) {
      setPages([1, 2, 3, '...', totalPages]) 
    }

    if (currentPage > 2 && currentPage < totalPages - 2) {
      setPages([
        1,
        '...',
        currentPage,
        '...',
        totalPages
      ]) 
    }
    if (currentPage > totalPages - 3) {
      setPages([
        1,
        '...',
        totalPages - 2,
        totalPages - 1,
        totalPages
      ]) 
    }
  }, [currentPage, totalPages]) 

  return (
    <div className='pagination'>
      <div className='container'>
        <button
          onClick={() => currentPage > 1 && handlePagination(currentPage - 1)}
          className={`arrow ${currentPage > 1 ? 'active' : 'inactive'}`}
        >
          <Icon
            name='arrow'
            list='icons'
            stroke='black'
            className='rotate-90'
          />
        </button>

        {pages.map((item, index) => (
          <button 
            key={index}
            className={item !== '...' ? `item ${currentPage === item && 'active'}` : 'no-item'}
            onClick={() => {
              item !== '...' && handlePagination(item as number)
            }}>
            {item}
          </button>
        ))}

        <button
          className={`arrow ${currentPage < totalPages ? 'active' : 'inactive'}`}
          onClick={() => currentPage < totalPages && handlePagination(currentPage + 1)}
        >
          <Icon
            name='arrow'
            list='icons'
            stroke='black'
            className='rotate-270'
          />
        </button>
      </div>
    </div>
  )
}

export default Pagination