import { RefObject, useEffect } from "react"

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: Function) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref.current || ref.current.contains(event.target as Element)) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },

    [ref, handler]
  )
}

export default useOnClickOutside