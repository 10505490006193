import { Icon } from 'components'
import { useScreen } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { ICarousel } from './props'
import './styles.scss'

let interval : NodeJS.Timeout 

const Carousel = ({
  breakpoints,
  items,
  renderItem,
  autoplay,
  showArrows,
  showDots,
  timeInterval,
}: ICarousel) => {
  const screen = useScreen()
  const slideshow = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const [indexCurrent, setIndexCurrent] = useState(0)

  const getDataOrder = (children: HTMLElement) => {
    if(!children) return 0
    const dataOrder = children?.getAttribute('data-order')
    const dataOrderNumber = Number(dataOrder)
    return dataOrderNumber
  }

  const autoplayInterval = () => {
    clearInterval(interval)

    if(items?.length < 2) return

    if (autoplay) {
      interval = setInterval(() => next(), (timeInterval * 1000))
    }
  }

  const next = () => {
    if (loading) return
    setLoading(true)

    const slide = slideshow.current

    if (!slide) return
    if (slide.children.length) {
      const firstChildren: HTMLElement | null = slide.children[0] as HTMLElement
      const secondChildren: HTMLElement | null = slide.children[1] as HTMLElement
      const sizeSlide = firstChildren.offsetWidth

      setIndexCurrent(getDataOrder(secondChildren))
  
      slide.style.transform = `translateX(-${sizeSlide}px)`
      slide.style.transition = `500ms ease-out all`

      setTimeout(() => {
        if (!slide) return
        slide.style.transition = `none`
        slide.style.transform = `translateX(0)`

        slide.appendChild(firstChildren)
        setLoading(false)
        autoplayInterval()
      }, 500)
    }
  }

  const back = async () => {
    if (loading) return
    setLoading(true)

    const slide = slideshow.current

    if (!slide) return
    if (slide.children.length) {
      const firstChildren: HTMLElement | null = slide.children[0] as HTMLElement
      const lastChildren: HTMLElement = slide.children[items?.length - 1] as HTMLElement
      slide.insertBefore(lastChildren, firstChildren)

      setIndexCurrent(getDataOrder(lastChildren))

      const sizeSlide = firstChildren?.offsetWidth

      slide.style.transform = `translateX(-${sizeSlide}px)`
      slide.style.transition = `none`

      setTimeout(() => {
        if (!slide) return
        slide.style.transition = `500ms ease-out all`
        slide.style.transform = `translateX(0)`
      }, 50)

      await new Promise((resolve) => setTimeout(resolve, 500))
      setLoading(false)
      autoplayInterval()
    }
  }

  const selectItem = (index: number) => {
    if (loading) return
    setLoading(true)

    const slide = slideshow.current

    if (!slide) return
    if (slide.children.length) {
      do{
        const firstChildren: HTMLElement | null = slide.children[0] as HTMLElement
        const dataOrder = firstChildren?.getAttribute('data-order')
        const dataOrderNumber = Number(dataOrder)

        const diff = index - dataOrderNumber

        if (diff > 0) {
          slide.appendChild(slide.children[0])
        }

        if (diff < 0) {
          const lastChildren = slide.children[items?.length - 1] as HTMLElement
          slide.insertBefore(lastChildren, firstChildren)
        }
      }while (index !== Number(slide.children[0].getAttribute('data-order')))

      setIndexCurrent(getDataOrder(slide.children[0] as HTMLElement))
      setLoading(false)
      autoplayInterval()
    }
  }

  useEffect(() => {
    if (breakpoints) {
      let breakpoint = breakpoints[breakpoints.length - 1]
      for (let i = 0 ; i < breakpoints.length; i++) {
        if (screen.width >= breakpoints[i].width) {
          breakpoint = breakpoints[i]
        }
      }

      const slidesPerView =
        breakpoint.slidesPerView > items?.length
          ? items?.length
          : breakpoint.slidesPerView

      const itemWidth = 100 / slidesPerView
  
      const list: NodeListOf<HTMLElement> = document.querySelectorAll(`.carousel .content .item`);
      list.forEach((item: HTMLElement) => (item.style.minWidth = `${itemWidth}%`));
    }
  }, [screen, items])

  useEffect(() => {
    autoplayInterval()
    return () => clearInterval(interval)
  }, [timeInterval])

  return (
    <div className='carousel-wrapper'>
      <div className='carousel'>
        {showArrows && (
            <Icon
              className='rotate-90 arrow-left'
              name='arrow'
              list='icons'
              stroke='black'
              onClick={back}
            />
          )
        }

        <div className='content'>
          <div className='items' ref={slideshow}>
            {items?.map((item, index: number) => (
              <div className='item' key={index} data-order={index}>
                {renderItem(item)}
              </div>
            ))}
          </div>
        </div>

        {showDots && (
          <div className='dots'>
            {items?.map((x, index: number) => (
              <div
                key={index}
                className={`dot ${index === indexCurrent  ? 'bg-white ' : 'bg-extraGray'}`}
                onClick={() => selectItem(index)}
              />
            ))}
          </div>
        )}

        {showArrows && (
          <Icon
            className='rotate-270 arrow-right'
            name='arrow'
            list='icons'
            stroke='black'
            onClick={next}
          />
        )}
      </div>
    </div>
  )
}

Carousel.defaultProps = {
  breakpoints: [
    {
      width: 0,
      slidesPerView: 1,
    }
  ],
  autoplay: false,
  showArrows: true,
  showDots: false,
}

export default Carousel
