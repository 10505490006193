import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ICheckbox } from './props'
import './styles.scss'

const Checkbox = ({
  value,
  onChange,
  error,
  text,
  id,
  linkText,
  linkHref,
}: ICheckbox) => {
  const navigate = useNavigate()
  const refCheckbox = useRef<HTMLInputElement>(null)

  const handleHref = () => {
    if(!linkHref) return
    if(linkHref.includes('http')) return window.open(linkHref, '_blank')
    else return navigate(linkHref)
  }

  return (
    <div className='checkbox'>
      <div className='wrapper'>
        <input
          type='checkbox'
          id={id}
          ref={refCheckbox}
          className={error ? 'error' : ''}
          checked={value}
          onChange={(e) => onChange?.(e.target.checked)}
        />

        <label htmlFor={refCheckbox?.current?.id}>
          {text} {linkText &&
            <span className='link' onClick={handleHref}>
              {linkText}
            </span>
          } 
        </label>
      </div>
    </div>
  )
}

export default Checkbox