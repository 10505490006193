import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import links from 'constants/links'
import API from 'tools/api'
import { IGetSubCategory } from './props'

export const getSubCategory = async({
  category,
  subcategory,
  setLoading,
  setItem,
  navigate,
}: IGetSubCategory) => {
  try{
    setLoading(true)

    if(!category || !subcategory) throw new Error('Not found')

    const response =  await API.request({
      method: 'GET',
      path: endpoints.pathSubcategory.replace(':category', category).replace(':subcategory', subcategory),
    })

    if(!response.data) throw new Error(response.error)

    setItem(response.data)
  }catch(error: any){
    console.error(error)

    if(error.message === 'Not found'){
      return navigate(links.notFound)
    }

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later',
    })
  }finally{
    setLoading(false)
  }
}