import { Footer, Header } from 'components'
import { useGlobal } from 'context/global'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createEditor } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import { getInformation, renderElement, renderLeaf } from './services'
import './styles.scss'

const Information = () => {
  const navigate = useNavigate()
  const [editor] = useState(() => withReact(createEditor()))
  const { setLoading } = useGlobal()
  const { information } = useParams()
  const [item, setItem] = useState({
    title: '',
    content: null,
  })

  useLayoutEffect(() => {
    if(!information) return
    window.scrollTo(0, 0)

    document.title =  item?.title || 'Information'
  }, [item?.title])

  useLayoutEffect(() => {
    setItem({
      title: '',
      content: null,
    })
    
    getInformation({
      information,
      setLoading,
      setItem,
      navigate,
    })
  }, [window.location.pathname])

  const handleRenderElement = useCallback(renderElement, [])
  const handleRenderLeaf = useCallback(renderLeaf, [])

  return (
    <>
      <Header />
    
      <div className='information'>
        <div className='container'>
          {item.content &&
            <Slate editor={editor} initialValue={item.content}>
              <Editable
                renderElement={handleRenderElement}
                renderLeaf={handleRenderLeaf}
                readOnly
              />
            </Slate>
          }
        </div>
      </div>
    
      <Footer />
    </>
  )
}

export default Information