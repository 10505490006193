import { useEffect, useState } from 'react'

interface Screen {
  width: number
  height: number
}

const useScreen = (): Screen => {
  const [screen, setScreen] = useState<Screen>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const handler = () => {
    setScreen({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return screen
}

export default useScreen