import imageNotFound from 'assets/images/404.png'
import { Button, Footer, Header } from 'components'
import links from 'constants/links'
import { useNavigate } from 'react-router-dom'
import './styles.scss'

const NotFound = () => {
  const navigate = useNavigate()

  const navigateHome = () => navigate(links.home)

  return (
    <>
      <Header/>

      <div className='not-found'>
        <div className='container'>
          <section className='left'>
            <h1>
              Something Went Wrong...
            </h1>

            <p>
              Looks line you've wandered off the beaten path. Our team is working to get ypu back on track and find what you're looking for
            </p>

            <Button
              text='Back to Home'
              size='md'
              onClick={navigateHome}
            />
          </section>
    
          <section className='right'>
            <img
              src={imageNotFound}
              alt='Not Found'
            />
          </section>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default NotFound