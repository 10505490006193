import FloatingMenu from 'components/floatingMenu'
import { useGlobal } from 'context/global'
import './styles.scss'

const NavbarDesktop = () => {
  const { paths } = useGlobal()

  return (
    <nav className='navbar-desktop'>
      <div className='list'>
        {paths.map((item, index) => (
          <FloatingMenu
            key={index}
            pathCurrent={window.location.pathname}
            path={item}
          />
        ))}
      </div>
    </nav>
  )
}

export default NavbarDesktop