export const rules = {
  firstName: {
    required: 'First name is required',
    pattern: {
      value: /^[A-Zña-z]+$/i,
      message: 'First name must contain only letters'
    }
  },
  lastName: {
    required: 'Last name is required',
    pattern: {
      value: /^[A-Zña-z]+$/i,
      message: 'Last name must contain only letters'
    }
  },
  companyName: {
    required: 'Company name is required',
  },
  phone: {
    required: 'Phone is required',
  },
  companyWebsite: {
    required: false,
  },
  companySize: {
    required: false,
  },
  productToDiscuss: {
    required: 'Product to discuss is required',
  },
  industry: {
    required: 'Industry is required',
  },
  message: {
    required: false,
  },
  accepted: {
    required: 'You must accept the terms and conditions',
  }
}