import logo from 'assets/images/logo.svg'
import { Button, Icon, InputSearch } from 'components'
import links from 'constants/links'
import { useGlobal } from 'context/global'
import { useDebounce, useMediaQuery } from 'hooks'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { NavbarDesktop, NavbarMobile } from './childrens'
import { IResultsSearch } from './props'
import { getSearch } from './services'
import './styles.scss'

const Header = () => {
  const { search, setSearch } = useGlobal()
  const navigate = useNavigate()
  const [openSidebar, setOpenSidebar] = useState(false)
  const isMobileScreen = useMediaQuery(768)
  const isTabletScreen = useMediaQuery(930)
  const isDesktopScreen = useMediaQuery(1024)
  const searchDebounce = useDebounce(search, 500)
  const [resultsSearch, setResultsSearch] = useState<IResultsSearch[]>([])
  const [loadingSearch, setLoadingSearch] = useState(false)

  const toggleSidebar = () => setOpenSidebar(!openSidebar)

  const returnMathes = (result: IResultsSearch, search: string) => {
    const regex = new RegExp(search, 'gi')
    const matchesTitle = result.title.match(regex) || []
    const matchesDescription = result.description.match(regex) || []
    const matches = [...matchesTitle, ...matchesDescription]
    return matches?.length || 0
  }

  useEffect(() => {
    setSearch('')
    setOpenSidebar(false)
  }, [window.location.pathname])
  
  useEffect(() => {
    getSearch({
      search: searchDebounce,
      setResultsSearch,
      setLoading: setLoadingSearch,
    })
  }, [searchDebounce])

  return (
    <>
      <header className='header'>
        <div className='container'>
          <section className='left'>
            {isMobileScreen && (
              <Icon
                name='menu'
                className='icon-menu'
                list='icons'
                onClick={toggleSidebar}
              />
            )}

            <Link to={links.home}>
              <img
                src={logo}
                alt='logo'
                className='logo'
              />
            </Link>

            {!isMobileScreen && <NavbarDesktop />}
          </section>

          <section className='right'>
            {!isTabletScreen && (
              <InputSearch
                placeholder='Search'
                icon='search'
                listIcon='icons'
                value={search}
                onChange={setSearch}
                renderBody={() => (
                  <div className='body-search'>
                    {loadingSearch && <span className='title'>Searching...</span>}
                    {!loadingSearch && (
                      <>
                        {resultsSearch?.length === 0 && <span className='title'>No results</span>}
                        {resultsSearch?.length > 0 &&
                          <>
                            <span className='title'>Matches found</span>
                            {resultsSearch?.map((result, index: number) => (
                              <Link
                                key={index}
                                to={result.path}
                                className='result'
                              >
                                <span
                                  className='subcategory'
                                >
                                  {result.title} ({returnMathes(result, search)})
                                </span>
                              </Link>
                            )
                            )}
                          </>
                        }
                      </>
                    )}
                  </div>
                )}
              />
            )}

            <Button
              text={isDesktopScreen ? 'Free Tour' : 'Free Product Tour'}
              size='md'
              onClick={() => navigate(links.productTour)}
            />
          </section>
        </div>
      </header>
  
      {
        isMobileScreen  &&
        <NavbarMobile
          toggleSidebar={toggleSidebar}
          openSidebar={openSidebar}
          loadingSearch={loadingSearch}
          resultsSearch={resultsSearch}
          returnMathes={returnMathes}
        />
      }
    </>
  )
}

export default Header