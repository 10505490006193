import { ITextarea } from './props'
import './styles.scss'

const Textarea = ({
  label,
  value,
  onChange,
  placeholder,
}: ITextarea) => {
  return (
    <div className={`textarea`}>
      <label>{label}</label>
      <textarea
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}

export default Textarea