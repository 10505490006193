import logo from 'assets/images/logo.svg'
import { useGlobal } from 'context/global'
import { ILinkFooter } from 'interfaces/linkfooter.interface'
import { Fragment, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { ListPath } from './childrens'
import './styles.scss'

const ComponentLink = ({ item }: { item: ILinkFooter }) => {
  const isExternal = item.link.includes('http')

  return (
    <Fragment>
      {isExternal ? (
        <a href={item.link} target='_blank' rel='noreferrer'>
          {item.title}
        </a>
      ) : (
        <Link to={item.link}>{item.title}</Link>
      )}
    </Fragment>
  )
}

const Footer = () => {
  const refLeftDesktop = useRef<HTMLDivElement>(null)
  const { paths, linksFooter } = useGlobal()

  useEffect(() => {
    refLeftDesktop.current?.style.setProperty('grid-template-columns', `repeat(${paths.length - 1}, 1fr)`)
  }, [paths])

  return (
    <footer className='footer'>
      <div className='container'>
        <section className='body'>
          <section className='left' ref={refLeftDesktop}>
            {paths.map((item, index) =>  (
              <Fragment key={index}>
                {item.list?.length > 0 && <ListPath item={item} />}
              </Fragment>
            ))}
          </section>

          <section className='right'>
            <img src={logo} className='logo' alt='logo' />
          </section>
        </section>

        <section className='foot'>
          <span>Copyright © Tiory All Right Reserved</span>

          <div>
            {linksFooter.map((item, index) => (
              <ComponentLink key={index} item={item} />
            ))}
          </div>

          <img src={logo} className='logo' alt='logo' />
        </section>
      </div>
    </footer>
  )
}

export default Footer