import { useLayoutEffect, useRef } from 'react'
import { ISidebar } from './props'
import './styles.scss'

const Sidebar = ({
  open,
  onClose,
  children,
  classNameSidebar,
  classNameContent,
}: ISidebar) => { 
  const refContent = useRef<HTMLDivElement>(null)

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isClickRight = e.button === 0
    const isOut = !(refContent?.current?.contains(e.target as Element))

    if(isClickRight && isOut) onClose()
  }

  useLayoutEffect(() => {
    if(open) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [open])

  if (!open) return null

  return (
    <div
      className={`sidebar ${classNameSidebar}`}
      onMouseDown={onMouseDown}
    >
      <div 
        className={`content ${classNameContent}`}
        ref={refContent}
      >
        {children}
      </div>
    </div>
  )
}

export default Sidebar