import { Carousel, Footer, Header, InfoCard } from 'components'
import { useGlobal } from 'context/global'
import { useMediaQuery } from 'hooks'
import { useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getHome } from './services'
import './styles.scss'

const Home = () => {
  const isMobile = useMediaQuery(768)
  const { setLoading } = useGlobal()
  const [items, setItems] = useState({
    title: 'Home',
    intervalCarousel: 1,
    carousel: [],
    cards: []
  })

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    document.title = items?.title
  }, [])

  useLayoutEffect(() => {
    getHome({
      setItems,
      setLoading,
    })
  }, [])

  return (
    <>
      <Header />
      <Carousel
        items={items?.carousel}
        showArrows={false}
        showDots={true}
        autoplay={true}
        timeInterval={items?.intervalCarousel}
        renderItem={(item) => (
          <Link
            to={item?.link || ''}
            className={`home_carousel_item ${!item?.link ? 'c-default' : ''}`}
          >
            <img
              src={
                isMobile
                  ? item?.imageMobile?.url || item?.image?.url
                  : item?.image?.url
              }
              alt='carousel'
            />
          </Link>
        )}
      />

      {items?.cards?.map((
        item: { title: string, description: string, image: { url: string }, buttonText:string, buttonLink:string },
        index: number
      ) => (
        <InfoCard
          key={index}
          title={item?.title}
          titleFontWeight='700'
          description={item?.description}
          image={item?.image?.url}
          link={item?.buttonLink}
          linkText={item?.buttonText}
          mobileTextAlign='center'
          titleColor='darkBlack'
          textColor='lightBlack'
          backgroundColor={index % 2 === 0 ? 'white' : 'lightGray'}
          buttonTextColor='white'
          buttonColor={index % 2 === 0 ? 'orange' : 'deepBlue'}
        />
      ))}

      <Footer />
    </>
  )
}

export default Home