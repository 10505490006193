const colors = {
  orange: '#EE5E24',
  deepBlue: '#191948',
  lightBlue: '#EBF4FF',
  lightGray: '#F2F4F8',
  gray: '#E8E7E7',
  blue: '#253B74',
  darkBlue: '#384354',
  extraGray: '#929497',
  white: '#FFFFFF',
  black: '#000000',
  blackMedium : '#232830',
  darkBlack: '#121212',
  lightBlack: '#444449',
  green: '#6CC516',
}

export default colors