import { Loading } from 'components'
import envs from 'constants/envs'
import { SCRIPT_CAPTCHA } from 'constants/values'
import { useGlobal } from 'context/global'
import { useScript } from 'hooks'
import { useEffect } from 'react'
import Routes from 'routes'

declare global {
  interface Window {
    grecaptcha: { execute: Function }
  }
}

const App = () => {
  const { loading } = useGlobal()
  useScript(SCRIPT_CAPTCHA.replace('{key}', envs.captchaKey))

  useEffect(() => {
    const list = document.getElementsByClassName('grecaptcha-badge')
    
    for(let i = 0; i < list.length; i++) {
      const badge = list[i] as HTMLElement
      badge.style.zIndex = '995'
    }
  }, [window.grecaptcha])

  return (
    <>
      <Routes />
      { loading && <Loading complete /> }
    </>
  )
}

export default App
