import toast from 'components/toast'
import { endpoints } from 'constants/endpoints'
import API from 'tools/api'
import { IGetSearch } from './props'

export const getSearch = async ({
  setLoading,
  search,
  setResultsSearch,
}: IGetSearch) => {
  try{
    setLoading(true)

    if(!search) return setResultsSearch([])
  
    const response = await API.request({
      method: 'GET',
      path: endpoints.search,
      params: {
        q: search
      }
    })

    setResultsSearch(response.data)
  }catch(error){
    console.error(error)

    toast({
      type: 'error',
      message: 'Something went wrong, please try again later'
    })
  }finally{
    setLoading(false)
  }
}