import { useEffect } from 'react'

const scriptStatus: Record<string, boolean> = {}

export default function useScript(url: string) {
  useEffect(() => {
    if (scriptStatus[url]) return

    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.onload = () => {
      scriptStatus[url] = true
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}
