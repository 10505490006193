import links from 'constants/links'
import { useGlobal } from 'context/global'
import { Category, Contact, Home, Information, NotFound, ProductTour, Subcategory } from 'pages'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom'
import { getPaths } from 'tools/functions'

const Routes = () => {
  const { setPaths, setLinksFooter } = useGlobal()

  useEffect(() => {
    getPaths(setPaths, setLinksFooter)
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route path={links.home} element={<Home />} />
        <Route path={links.category} element={<Category />} />
        <Route path={links.subcategory} element={<Subcategory />} />
        <Route path={links.contact} element={<Contact />} />
        <Route path={links.productTour} element={<ProductTour />} />
        <Route path={links.information} element={<Information />} />
        <Route path={links.notFound} element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes