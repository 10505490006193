const links = {
  home: '/',
  category: '/categories/:category',
  subcategory: '/categories/:category/:subcategory',
  contact: '/contact',
  productTour: '/product-tour',
  information: '/:information',
  notFound: '/not-found',
}

export default links